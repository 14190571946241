<template class="b-card">
  <b-card>
    <validation-observer ref="form" tag="form">
      <b-form>
        <b-row>

          <!-- Title -->
          <b-col md="6">
            <b-form-group :label="$t('label.Title')" :label-for="$t('label.Title')">
              <validation-provider :name="$t('label.Title')" rules="required" v-slot="{ errors }">
                <b-form-input
                  id="name" trim
                  :placeholder="$t('placeholder.title')"
                  v-model="addOrEditComm.title">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Locations -->
          <b-col md="4">
            <b-form-group :label="$t('label.Locations')" :label-for="$t('label.location')">
              <validation-provider :name="$t('label.Locations')" rules="required" v-slot="{ errors }">
                <v-select
                  id="location"
                  :reduce="(locations) => locations.value"
                  :options="locations"
                  :multiple="true"
                  label="title"
                  :placeholder="$t('placeholder.locations')"
                  v-model="selectedLocations"
                  :disabled="allLocations"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- All locations -->
          <b-col md="2">
            <b-form-group :label="$t('message.allLocations')" label-for="all-locations">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                style="transform: scale(1.1); margin-left: .5rem;"
                @change="handleCheckboxChange"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
          
        <b-row>
          <!-- Date range -->
          <b-col md="6">
            <b-form-group :label="$t('message.tableHeader.dateRange')" label-for="v-date">
              <flat-pickr
                v-model="dateRange"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>
          
          <!-- Image -->          
          <b-col md="6">
            <b-form-group  label-for="v-img"  class="d-flex align-items-center">
              <label>{{ $t('label.img') }}</label>
              <div class="d-flex align-items-center">
                <b-button
                  :variant="imgData ? 'outline-secondary' : 'info'"
                  @click="uploadImage()"
                  > {{ imgData ? $t('message.modify') : $t('message.upload') }}
                </b-button>
                <div v-if="imgData" class="mt-2 ml-1">
                  <feather-icon
                      icon="PaperclipIcon"
                      size="15"
                      style="opacity: 0.5;"
                  />
                  <small v-if="imgData" class="text-secondary">{{ imgData.name }}</small>
                </div>
              </div>
              <image-compressor
                ref="compressor"
                style="display: none"
                :done="getImg"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row b-col md="12">

          <!-- Detail -->
          <b-col>      
            <b-form-group :label="$t('message.tableHeader.detail')" :label-for="$t('message.tableHeader.detail')">
                <validation-provider :name="$t('message.tableHeader.detail')" rules="required" v-slot="{ errors }">
                  <quill-editor
                    :placeholder="$t('message.tableHeader.detailMsg')"
                    v-model="addOrEditComm.detail"
                    :options="editorOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      
        <!-- Submit -->
        <b-col class="text-right mt-3 pr-0" cols="12">
          <b-button
            variant="primary"
            @click="validateAndSubmit(null, 'add')"
          > {{ $t('message.submit') }}
          </b-button>
        </b-col>

      </b-form>
    </validation-observer>
  </b-card>
</template>

    
<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormDatepicker, BFormTextarea, BFormCheckbox } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import flatPickr from "vue-flatpickr-component"
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import useCommunications from './useCommunications'
import { watch, ref } from '@vue/composition-api'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    BFormTextarea,
    flatPickr,
    ImageCompressor,
    quillEditor,
    BFormCheckbox
  },

  setup() {

    const {
      locations,
      dateRange,
      form,
      compressor,
      validateAndSubmit,
      datePickerConfig,
      uploadImage,
      getImg,
      addOrEditComm,
      imgData,
      selectedLocations,
      editorOptions,
      handleCheckboxChange,
      allLocations,
    } = useCommunications()

    watch(locations, (newLocations) => {
      if (newLocations.length > 0) {
        selectedLocations.value = [newLocations[0].value]
      }
    })

    return {
      locations,
      dateRange,
      form,
      compressor,
      validateAndSubmit,
      datePickerConfig,
      uploadImage,
      getImg,
      addOrEditComm,
      imgData,
      selectedLocations,
      editorOptions,
      handleCheckboxChange,
      allLocations,
      locations
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/quill.scss';
</style>